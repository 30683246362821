import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useFormContext, get, FieldError } from 'react-hook-form';
import { InputWrapperProps } from '../types';

const InputWrapper: FC<InputWrapperProps> = ({
  name,
  label,
  helperTxt,
  isStandalone,
  children,
  variant = 'floating',
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl variant={variant} isInvalid={!!get(errors, name)}>
      <FormLabel color={!get(errors, name) ? 'inputError' : undefined}>
        {label}
      </FormLabel>
      {children}
      {!isStandalone && (
        <>
          <FormErrorMessage alignItems="top" minH={5} fontSize="size2" m={0}>
            {(get(errors, name) as FieldError)?.message}
          </FormErrorMessage>
          {!get(errors, name) && (
            <FormHelperText minH={5} fontSize="size2" m={0}>
              {helperTxt}
            </FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
};

export default InputWrapper;
