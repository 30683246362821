import { Button, Heading, VStack, Link, Box, Flex } from '@chakra-ui/react';
import { FC, FormEvent } from 'react';
import { getCookie } from 'typescript-cookie';
import { FcGoogle } from 'react-icons/fc';
import {
  CSRF_TOKEN_COOKIE_NAME,
  CSRF_HIDDEN_FORM_FIELD_NAME,
} from '../api/axiosConfig';
import { AuthHTMLFormElement } from '../types/login';
import { loginButton, loginHeading } from '../styles/login';

export const Index: FC = () => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // update CSRF token hidden field value on submit
    (e.target as AuthHTMLFormElement)[CSRF_HIDDEN_FORM_FIELD_NAME].value =
      getCookie(CSRF_TOKEN_COOKIE_NAME);
  };

  return (
    <Flex height="100%">
      <Box alignSelf="center" width="100%">
        <VStack>
          <Heading sx={loginHeading} size="lg">
            ZALOGUJ SIĘ
            <br />
            DO MODUŁU LIDERSKIEGO
          </Heading>
          <VStack as="nav">
            <form
              method="POST"
              action={`${
                process.env.REACT_APP_BACKEND_URL || ''
              }/accounts/google/login/`}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                name={CSRF_HIDDEN_FORM_FIELD_NAME}
                value=""
              />
              <Button
                type="submit"
                leftIcon={<FcGoogle size={32} />}
                sx={loginButton}
              >
                ZALOGUJ PRZEZ GOOGLE
              </Button>
            </form>
            <Link color="active" href={process.env.REACT_APP_INTRANET_URL}>
              Cofnij
            </Link>
          </VStack>
        </VStack>
      </Box>
    </Flex>
  );
};
