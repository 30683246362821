import { useMemo } from 'react';
import { ProjectWithId } from '../types';
import { ColumnInfo } from '../../../components/MainTable/types';

export const useProjectsColumnDef = () => {
  const columnInfo: Array<ColumnInfo<ProjectWithId>> = useMemo(
    () => [
      {
        id: 'name',
        header: 'Nazwa',
        getRow: (row: ProjectWithId) => row.name,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'leader',
        header: 'Lider',
        getRow: (row: ProjectWithId) =>
          `${row.leader?.firstName || ''} ${row.leader?.lastName || ''}`,
        sortType: 'text' as const,
      },
      {
        id: 'Status',
        header: 'Status',
        getRow: (row: ProjectWithId) => row.status,
        sortType: 'text' as const,
      },
    ],
    []
  );

  return { columnInfo };
};
