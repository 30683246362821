import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ProjectQueryKey } from '../types';
import { fetchMeanVelocities, fetchProject } from '../utils';

export const useMeanVelocitiesQuery = (projectId: number) => {
  const queryKey: ProjectQueryKey = useMemo(
    () => ['meanVelocities', projectId],
    [projectId]
  );

  return useQuery(queryKey, fetchMeanVelocities, {});
};

export const useProjectQuery = (projectId: number) => {
  const queryKey: ProjectQueryKey = useMemo(
    () => ['project', projectId],
    [projectId]
  );

  return useQuery(queryKey, fetchProject, {});
};
