import { colors } from 'styles/colors';

export const selectStyling = {
  borderWidth: '1px',
  borderRadius: '0.375rem',
  backgroundColor: colors.white,
  textAlign: 'left',
  textTransform: 'none',
  height: '40px',
  width: '100%',
  _hover: { backgroundColor: colors.white },
};
