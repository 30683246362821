import { Button, Icon, Text, useDisclosure } from '@chakra-ui/react';
import CenteredSpinner from 'components/CenteredSpinner';
import { MainContainer } from 'components/MainContainer';
import { MainTable } from 'components/MainTable';
import { FC } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AddProject } from './add';
import { useProjectsColumnDef } from './hooks/useProjectsColumnDef';
import { useProjectsQuery } from './hooks/useProjectsQuery';
import { addProjectButtonStyling } from './styles';
import { ProjectWithId } from './types';

const Projects: FC = () => {
  const projects = useProjectsQuery();
  const { columnInfo } = useProjectsColumnDef();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addDisclosure = useDisclosure();

  if (projects.isLoading) return <CenteredSpinner />;

  return projects.data?.data.results ? (
    <MainContainer>
      <AddProject queryClient={queryClient} {...addDisclosure} />
      <MainTable<ProjectWithId>
        data={projects.data.data.results}
        columnInfo={columnInfo}
        rowClick={(row) => navigate(`/project/${row.original.id}/`)}
        extraButton={
          <Button
            leftIcon={<Icon as={MdAdd} />}
            onClick={() => addDisclosure.onOpen()}
            sx={addProjectButtonStyling}
          >
            <Text>Dodaj projekt</Text>
          </Button>
        }
      />
    </MainContainer>
  ) : null;
};

export default Projects;
