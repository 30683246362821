import {
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { FC } from 'react';
import { MdEdit, MdAdd, MdOutlineRefresh } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import CenteredSpinner from 'components/CenteredSpinner';
import LineChart from 'components/Charts/LineChart';
import { MainContainer } from 'components/MainContainer';
import { projectStatusOptions } from 'pages/projects/consts';
import { h1Typography, mainVStack, subContainer } from 'styles/global';
import { DetailDisplay } from './components/DetailDisplay';
import { useProjectQuery, useMeanVelocitiesQuery } from './hooks/query';
import { useProject } from './hooks/useProject';
import { useSyncProjectMutation } from './hooks/useSyncProject';
import { editButtonStyling, exportProjectButtonStyling } from './styles';

const ProjectSummary: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const project = useProjectQuery(Number(id));
  const meanVelocities = useMeanVelocitiesQuery(Number(id));
  const { mutate, isLoading } = useSyncProjectMutation(Number(id), () => {
    void project.refetch();
    void meanVelocities.refetch();
  });
  const mappedMeanVelocities = useProject(meanVelocities.data?.data.results);

  if (project.isLoading || meanVelocities.isLoading) return <CenteredSpinner />;

  if (!project.data || !meanVelocities.data) return null;

  return (
    <MainContainer>
      <VStack sx={mainVStack}>
        <HStack justifyContent="space-between" width="100%">
          <HStack>
            <Heading as="h1" sx={h1Typography}>
              {project.data.data.results.name}
            </Heading>
            <IconButton
              aria-label="edit-project"
              icon={<Icon sx={editButtonStyling.icon} as={MdEdit} />}
              sx={editButtonStyling.button}
              onClick={() => console.log('Edit: ', project.data.data.results)}
            />
          </HStack>
          <HStack>
            <Button
              leftIcon={
                isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Icon as={MdOutlineRefresh} />
                )
              }
              sx={exportProjectButtonStyling}
              disabled={isLoading}
              onClick={() => mutate()}
            >
              <Text>Synchronizuj</Text>
            </Button>
            <Button
              leftIcon={<Icon as={MdAdd} />}
              sx={exportProjectButtonStyling}
            >
              <Text>Eksport do pdf</Text>
            </Button>
          </HStack>
        </HStack>
        <HStack sx={subContainer}>
          <DetailDisplay
            value={`${project.data.data.results.leader?.firstName || ''} ${
              project.data.data.results.leader?.lastName || ''
            }`}
            label="Lider"
            bold
            showBorder
          />
          <DetailDisplay
            value={
              projectStatusOptions.find(
                (option) => option.value === project.data.data.results.status
              )?.label || ''
            }
            label="status"
          />
        </HStack>
        {mappedMeanVelocities && (
          <LineChart
            chartData={mappedMeanVelocities.meanVelocitiesHour}
            mainHeader="Velocity zespołu (h/SP)"
          />
        )}
        {mappedMeanVelocities && (
          <LineChart
            chartData={mappedMeanVelocities.meanVelocitiesMd}
            mainHeader="Velocity zespołu (SP/MD)"
          />
        )}
      </VStack>
    </MainContainer>
  );
};

export default ProjectSummary;
