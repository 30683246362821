import { useMutation } from 'react-query';
import client from '../../../api/axiosConfig';
import { projectsApiUrl } from '../../../utils/paths';

export const useSyncProjectMutation = (
  projectId: number,
  onSuccess: () => void
) => {
  return useMutation(() => client.post(`${projectsApiUrl}${projectId}/sync/`), {
    onSuccess,
  });
};
