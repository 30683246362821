import { VStack, Text, Box } from '@chakra-ui/react';
import { FC } from 'react';
import { detailDisplayStyling } from './styles';
import { DetailDisplayProps } from './types';

export const DetailDisplay: FC<DetailDisplayProps> = ({
  label,
  value,
  bold,
  showBorder,
}) => (
  <VStack sx={detailDisplayStyling.mainContainer(showBorder)}>
    <Box sx={detailDisplayStyling.innerContainer}>
      <Text sx={detailDisplayStyling.value(bold)}>{value}</Text>
      <Text sx={detailDisplayStyling.label}>{label}</Text>
    </Box>
  </VStack>
);
