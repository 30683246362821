import { BaseSyntheticEvent, PropsWithChildren } from 'react';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

/*eslint-disable @typescript-eslint/no-misused-promises*/

interface Props<T> extends PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: SubmitHandler<T extends FieldValues ? any : any>;
}

const FormWrapper = <T,>({ children, onSubmit }: Props<T>) => {
  const submitHandler = async (data: T, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    await onSubmit(data, event);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { handleSubmit } = useFormContext<T extends FieldValues ? any : any>();

  return <form onSubmit={handleSubmit(submitHandler)}>{children}</form>;
};

export default FormWrapper;
