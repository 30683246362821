import { ChartOptions } from 'chart.js';
import { chartTextStyles } from '../styles';

export const LINE_CHART_OPTIONS: ChartOptions<'line'> = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: { font: chartTextStyles },
    },
  },
  scales: {
    x: {
      ticks: { font: chartTextStyles },
    },
    y: {
      ticks: { font: chartTextStyles },
    },
  },
};
