export const editButtonStyling = {
  button: {
    backgroundColor: 'transparent',
    _hover: { backgroundColor: 'transparent' },
    _focus: { backgroundColor: 'transparent' },
  },
  icon: { fontSize: '1.5rem' },
};

export const exportProjectButtonStyling = {
  color: 'white',
  backgroundColor: 'button.active',
  _hover: { backgroundColor: 'hover.button' },
  _focus: { backgroundColor: 'button.focus' },
  _disabled: { backgroundColor: 'button.active' },
};
