import { FC, useCallback } from 'react';
import { IconColumnProps } from './IconColumn.types';
import { Accordion, VStack } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { SIDEBAR_ACTIONS, BOTTOM_SIDEBAR_LIST } from '../Sidebar.consts';
import styles from './IconColumn.styles';
import SidebarButton from '../SidebarButton';

const IconColumn: FC<IconColumnProps> = ({
  isOpen,
  setIsOpen,
  expandedRows,
  setExpandedRows,
  type,
}) => {
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleAccordion = useCallback(
    (rowNumber: number) => {
      const isDuplicated = expandedRows.includes(rowNumber);
      const newExpandedArray = isDuplicated
        ? expandedRows.filter((i) => i !== rowNumber)
        : expandedRows.concat(rowNumber);

      setExpandedRows(newExpandedArray);
    },
    [expandedRows, setExpandedRows]
  );

  return (
    <VStack sx={styles.root}>
      <Accordion sx={styles.accordion} index={expandedRows}>
        <VStack sx={styles.accordionSection}>
          <SidebarButton
            key={`action-menu`}
            type={type}
            action={{ name: '', text: '', icon: MdMenu, items: [] }}
            toggleDrawer={toggleDrawer}
            toggleAccordion={() => toggleAccordion(0)}
          />
          {SIDEBAR_ACTIONS.map((action, i) => (
            <SidebarButton
              key={`action-${i}`}
              type={type}
              action={action}
              toggleDrawer={toggleDrawer}
              toggleAccordion={() => toggleAccordion(i + 1)}
            />
          ))}
        </VStack>
        <VStack sx={styles.accordionSection}>
          {BOTTOM_SIDEBAR_LIST.map((action, i) => (
            <SidebarButton
              key={`action-${i}`}
              type={type}
              action={action}
              toggleDrawer={toggleDrawer}
              toggleAccordion={() => {
                toggleAccordion(SIDEBAR_ACTIONS.length + i + 1);
              }}
            />
          ))}
        </VStack>
      </Accordion>
    </VStack>
  );
};

export default IconColumn;
