import {
  MdOutlineCalendarViewDay,
  MdCalendarViewDay,
  MdOutlineLogout,
} from 'react-icons/md';
import { projectsPath, logoutPath } from '../../../utils/paths';
import { RedirectList, GrouppedRedirectList } from '../../Layout/types';

export const SIDEBAR_ACTIONS: Array<RedirectList | GrouppedRedirectList> = [
  {
    name: 'projects',
    text: 'Projekty',
    icon: MdOutlineCalendarViewDay,
    path: projectsPath,
    activeIcon: MdCalendarViewDay,
  },
];

export const BOTTOM_SIDEBAR_LIST: Array<RedirectList> = [
  {
    name: 'logout',
    text: 'Wyloguj się',
    icon: MdOutlineLogout,
    activeIcon: MdOutlineLogout,
    path: logoutPath,
  },
];
