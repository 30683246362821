import { SelectOption } from 'types/selectOptions';
import { ProjectStatus } from './types';

export const projectStatusOptions: Array<SelectOption> = [
  { label: 'Product design', value: ProjectStatus.productDesign },
  { label: 'Development', value: ProjectStatus.development },
  { label: 'Utrzymanie', value: ProjectStatus.maintenance },
  { label: 'Zakończone', value: ProjectStatus.completed },
];

export const PROJECTS_QUERY_KEY = 'projects';
