import { Box } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { Line } from 'react-chartjs-2';
import { CHART_COLORS } from '../consts';
import { lineChartStyling } from '../styles';
import { LINE_CHART_OPTIONS } from './consts';
import { LineChartData, BaseLineChartData } from './types';

const getDataObj = (
  chartData: LineChartData | BaseLineChartData,
  shiftColorIndex = 0
) => ({
  labels:
    'horizontalAxislabels' in chartData
      ? chartData.horizontalAxislabels
      : undefined,
  datasets: chartData.datasets.map((dataset, index) => ({
    ...dataset,
    fill: false,
    borderColor: CHART_COLORS[index + shiftColorIndex],
    backgroundColor: '#fff',
    pointRadius: 8,
    pointHoverRadius: 8,
  })),
});

// This function checks, whether given barChartData should be passed to Line component.
// We don't want to display chart with datasets containing only zeros
// or without any datasets at all.
export const isLineChartDataValid = (barChartData: BaseLineChartData) => {
  if (!barChartData.datasets.length) return false;

  const allZeros = barChartData.datasets.every(({ data }) =>
    data.every((value) => value === 0)
  );

  return !allZeros;
};

export const getLineChartToDisplay = (firstLineChartData: LineChartData) => {
  const tempFirstData = cloneDeep(firstLineChartData);

  return (
    <Box sx={lineChartStyling.chart}>
      <Line
        datasetIdKey="line"
        options={LINE_CHART_OPTIONS}
        data={getDataObj(tempFirstData)}
      />
    </Box>
  );
};
