import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { defaultValidation } from '../validation';
import { SelectOption } from '../../../types/selectOptions';
import Select from '../../Select';
import { inputStyle } from '../../../styles/theme';
import InputWrapper from '../InputWrapper';
import { DefaultInputProps } from '../types';

interface Props extends DefaultInputProps {
  options: Array<SelectOption>;
}

interface RenderProps {
  field: {
    onChange: (value: string | Array<string>) => void;
    onBlur: Noop;
    value?: string;
    name: string;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}
export const FormSelect = ({
  registerProps = defaultValidation,
  name,
  label,
  helperTxt,
  options,
  isStandalone,
  defaultValue,
  autoFocus,
  placeholder,
}: Props) => {
  const { control } = useFormContext();
  return (
    <InputWrapper
      name={name}
      label={label}
      helperTxt={helperTxt}
      isStandalone={isStandalone}
    >
      <Controller
        rules={registerProps}
        control={control}
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }: RenderProps) => (
          <Box data-value={value === '' ? undefined : value}>
            <Select
              options={options}
              onMenuOptionGroupChange={onChange}
              labelFontSize={inputStyle.sizes.md.field.fontSize}
              popperFontSize={inputStyle.sizes.md.field.fontSize}
              fontWeight={inputStyle.sizes.md.field.fontWeight}
              paddingInlineStart="1rem"
              currentValue={value}
              error={error}
              defaultValue={defaultValue}
              autoFocus={autoFocus}
              placeholder={placeholder}
            />
          </Box>
        )}
      />
    </InputWrapper>
  );
};
