import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { LineChartProps } from './types';
import { barChartStyling } from '../styles';
import { getLineChartToDisplay, isLineChartDataValid } from './utils';

const LineChart: FC<LineChartProps> = ({ chartData, mainHeader }) =>
  isLineChartDataValid(chartData) ? (
    <Box sx={barChartStyling.container}>
      <Box sx={barChartStyling.containerHeader}>
        {mainHeader && <Text sx={barChartStyling.header}>{mainHeader}</Text>}
      </Box>
      <Flex>{getLineChartToDisplay(chartData)}</Flex>
    </Box>
  ) : null;

export default LineChart;
