export const tableBaseStyling = {
  container: { overflowY: 'auto', width: '100%' },
  table: { tableLayout: 'auto' },
  th: { paddingInlineStart: '0.5rem', paddingBlockEnd: '0.5rem' },
  td: { paddingInlineStart: '0.5rem', paddingBlockEnd: '0.5rem' },
  tr: {
    _hover: {
      backgroundColor: 'hover.table',
    },
    borderBottomWidth: '1px',
    borderBottomColor: 'borders.rowBottom',
  },
  iconContainer: { marginRight: '10px' },
};

export const tableContainerStyling = {
  firstContainer: {
    width: '100%',
    backgroundColor: 'background',
    height: '100%',
  },
  secondContainer: { width: '100%', padding: '1rem' },
  thirdContainer: {
    backgroundColor: 'white',
    padding: '1rem',
    borderWidth: '0.0625rem',
    borderRadius: '0.75rem',
    borderColor: 'borders.tableContainer',
    minHeight: '31.25rem',
  },
};

export const hstackStyling = {
  width: '100%',
  justifyContent: 'flex-end',
  paddingBottom: '1rem',
};

export const filtersStyling = {
  container: {
    alignSelf: 'flex-start',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',
    columnGap: '5%',
    maxWidth: '60rem',
  },
  header: { alignSelf: 'flex-start', fontSize: '0.875rem' },
  input: { fontSize: '1rem', minWidth: '5rem', backgroundColor: 'white' },
  inputContainer: { width: 'fit-content' },
};

export const tableHeadInnerStyling = {
  whiteSpace: 'nowrap',
  textAlign: 'left',
  width: '100%',
};

export const buttonIconStyling = {
  width: '2.625rem',
  height: '2.625rem',
  backgroundColor: 'transparent',
  _hover: { backgroundColor: 'transparent' },
  _focus: { backgroundColor: 'transparent' },
};
