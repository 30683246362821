import { useMemo, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
} from '@chakra-ui/react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  useReactTable,
  Row as ReactRowType,
} from '@tanstack/react-table';
import { TableStringFilter } from './filters/tableStringFilter';
import { ColumnInfo } from './types';
import {
  hstackStyling,
  tableBaseStyling,
  tableContainerStyling,
  tableHeadInnerStyling,
} from './styles';

interface Props<T> {
  data: Array<T>;
  columnInfo: Array<ColumnInfo<T>>;
  isModalOpen?: boolean;
  initialSorting?: SortingState;
  rowClick?: (row: ReactRowType<T>) => void;
  extraButton?: JSX.Element;
}

export const MainTable = <T,>({
  data,
  columnInfo,
  initialSorting,
  rowClick,
  extraButton,
}: Props<T>) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const columnHelper = createColumnHelper<T>();

  const columns = useMemo(
    () =>
      columnInfo.map(({ id, header, sortType, filterFn, getRow }) =>
        columnHelper.accessor(getRow, {
          id,
          header,
          sortingFn: sortType,
          filterFn,
        })
      ),
    [columnHelper, columnInfo]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const columnWithSearch = useMemo(() => {
    const info = columnInfo.find(
      (columnFilter) => columnFilter.filterFn === 'includesString'
    );

    if (!info) return;

    return table.getColumn(info.id);
  }, [columnInfo, table]);

  return (
    <VStack sx={tableContainerStyling.firstContainer}>
      <Box sx={tableContainerStyling.secondContainer}>
        <HStack sx={hstackStyling}>
          <Box>
            {columnWithSearch && (
              <TableStringFilter column={columnWithSearch} />
            )}
          </Box>
          {extraButton && <Box> {extraButton} </Box>}
        </HStack>
        <Box sx={tableContainerStyling.thirdContainer}>
          <TableContainer sx={tableBaseStyling.container}>
            <Table sx={tableBaseStyling.table} variant="unstyled">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        sx={tableBaseStyling.th}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <HStack
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {typeof header.column.columnDef.header ===
                              'string' ? (
                                <Text sx={tableHeadInnerStyling}>
                                  {header.column.columnDef.header}
                                </Text>
                              ) : (
                                flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )
                              )}
                              {header.column.getIsSorted() ? (
                                <Icon
                                  as={
                                    header.column.getIsSorted() === 'desc'
                                      ? MdArrowDropDown
                                      : MdArrowDropUp
                                  }
                                />
                              ) : undefined}
                            </HStack>
                          </>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    sx={tableBaseStyling.tr}
                    onClick={() => {
                      if (rowClick) {
                        rowClick(row);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        color={'inherit'}
                        sx={tableBaseStyling.td}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </VStack>
  );
};
