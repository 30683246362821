export const CONTAINERS_PADDING = '1rem';

export const iconSize = { fontSize: '1.5rem' };

export const basicContainer = {
  alignSelf: 'flex-start',
  width: '100%',
  paddingX: CONTAINERS_PADDING,
};

export const h1Typography = {
  fontSize: '1.5rem',
  fontStyle: '400',
};

export const mainVStack = {
  alignSelf: 'flex-start',
  // 64px (4rem) is the width of the sidebar (56px) + margin on mainVStack (8px)
  width: 'calc(100% - 4rem)',
  padding: `1rem`,
  marginInline: '0',
};

export const subContainer = {
  width: '100%',
  backgroundColor: 'white',
  padding: CONTAINERS_PADDING,
  borderWidth: '0.0625rem',
  borderRadius: '0.75rem',
  borderColor: 'borders.container',
};
