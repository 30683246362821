import { subContainer } from 'styles/global';

const baseChartStyling = {
  container: {
    display: 'block',
    maxWidth: '100%',
    ...subContainer,
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: '0.5rem',
  },
  header: { marginRight: '16px' },
  toggleContainer: { alignItems: 'center', flexWrap: 'wrap' },
  toggleTextContainer: { marginLeft: '8px', fontSize: '12px' },
};

export const chartTextStyles = { size: 12, weight: '400' };

export const barChartStyling = {
  ...baseChartStyling,
  chart: { width: '750px' },
};

export const lineChartStyling = {
  ...baseChartStyling,
  chart: { width: '100%' },
};
