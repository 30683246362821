import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Column as ReactColumnType } from '@tanstack/react-table';
import { MdSearch } from 'react-icons/md';
import { filtersStyling } from '../styles';

interface Props<T> {
  column: ReactColumnType<T>;
  label?: string;
}

export const TableStringFilter = <T,>({ column, label }: Props<T>) => {
  const filterValue = column.getFilterValue() ? column.getFilterValue() : '';

  return (
    <VStack>
      {label ? (
        <Text sx={{ ...filtersStyling.header }}>{label}</Text>
      ) : undefined}

      <InputGroup>
        <Input
          id={`${column.id}-string-filter`}
          value={filterValue as string}
          type="text"
          sx={filtersStyling.input}
          onChange={(value) => {
            column.setFilterValue(
              value.target.value.trim() ? value.target.value : undefined
            );
          }}
        />
        <InputRightElement>
          <Icon as={MdSearch} />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};
