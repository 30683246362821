import {
  Menu,
  MenuItemOption,
  MenuOptionGroup,
  MenuButton,
  MenuList,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { SelectOption } from '../../types/selectOptions';
import { selectStyling } from './styles';

interface Props {
  options: Array<SelectOption>;
  onMenuOptionGroupChange: (value: string | Array<string>) => void;
  labelFontSize?: string;
  popperFontSize?: string;
  fontWeight?: number;
  paddingInlineStart?: string;
  currentValue?: string;
  defaultValue?: string;
  error?: FieldError;
  autoFocus?: boolean;
  placeholder?: string;
}

const Select: FC<Props> = ({
  error,
  options,
  onMenuOptionGroupChange,
  labelFontSize,
  popperFontSize,
  fontWeight,
  paddingInlineStart,
  currentValue,
  defaultValue,
  autoFocus,
  placeholder,
}) => {
  const label = useMemo(
    () => options.find((option) => option.value === currentValue)?.label,
    [currentValue, options]
  );

  return (
    <Menu matchWidth={true}>
      <MenuButton
        aria-invalid={error ? true : false}
        name="menu-button"
        fontSize={labelFontSize}
        fontWeight={fontWeight}
        rightIcon={<MdArrowDropDown />}
        paddingInlineStart={paddingInlineStart}
        paddingInlineEnd="none"
        autoFocus={autoFocus}
        as={Button}
        placeholder={placeholder}
        sx={selectStyling}
      >
        <Text color={label ? 'inherit' : 'gray.500'}>
          {label || placeholder}
        </Text>
      </MenuButton>
      <MenuList maxHeight="400px" overflowY="auto" placeholder={placeholder}>
        <MenuOptionGroup
          onChange={onMenuOptionGroupChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
        >
          {options.map(({ value, label }) => (
            <MenuItemOption
              key={label}
              value={value}
              border="none"
              fontSize={popperFontSize}
              fontWeight={fontWeight}
            >
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default Select;
