import { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';
import { Index } from './pages';
import { theme } from './styles/theme';
import { ToastContainer } from './utils/toast';
import { handleError } from './utils/handleError';
import { ApiError } from './types/apiError';
import { Logout } from './pages/logout';
import UserProvider from 'Contexts/UserContext/userProvider';
import UserContext from 'Contexts/UserContext/userContext';
import CenteredSpinner from './components/CenteredSpinner';
import Projects from 'pages/projects';
import { Unauthorized } from 'pages/unauthorized';
import {
  logoutPath,
  projectPath,
  projectsPath,
  unauthorizedPath,
} from 'utils/paths';
import ProjectSummary from 'pages/projectSummary';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError(err) {
        handleError(err as ApiError);
      },
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <UserProvider>
        <AuthenticatedApp />
        <ToastContainer />
      </UserProvider>
    </ChakraProvider>
  </QueryClientProvider>
);

const AuthenticatedApp = () => {
  const { loggedUser, isLoading } = useContext(UserContext);
  const defaultUrl = loggedUser?.isProjectLeader
    ? projectsPath
    : unauthorizedPath;

  if (isLoading) return <CenteredSpinner />;

  return (
    <Router>
      <Routes>
        {!loggedUser && <Route path="/" element={<Index />} />}
        {loggedUser && <Route path={logoutPath} element={<Logout />} />}
        {loggedUser && (
          <Route path={unauthorizedPath} element={<Unauthorized />} />
        )}
        {loggedUser?.isProjectLeader && (
          <Route path={projectsPath} element={<Projects />} />
        )}
        {loggedUser?.isProjectLeader && (
          <Route path={projectPath} element={<ProjectSummary />} />
        )}
        <Route path="*" element={<Navigate to={defaultUrl} />} />
      </Routes>
    </Router>
  );
};

export default App;
