import { HStack } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import Sidebar from './Sidebar';

export const MainContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack backgroundColor="mainBackground" alignItems="stretch">
      <Sidebar />
      {children}
    </HStack>
  );
};
