import { Project } from 'pages/projects/types';
import { QueryFunctionContext } from 'react-query';
import client from '../../api/axiosConfig';
import { meanVelocitiesApiUrl, projectsApiUrl } from '../../utils/paths';
import { EmployeeSummary, ProjectQueryKey } from './types';

export const fetchMeanVelocities = (
  context: QueryFunctionContext<ProjectQueryKey>
) => {
  const [, projectId = ''] = context.queryKey;

  return client.get<{ results: Array<EmployeeSummary> }>(
    `${projectsApiUrl}${projectId}/${meanVelocitiesApiUrl}`
  );
};

export const fetchProject = (
  context: QueryFunctionContext<ProjectQueryKey>
) => {
  const [, projectId = ''] = context.queryKey;

  return client.get<{ results: Project }>(`${projectsApiUrl}${projectId}/`);
};
