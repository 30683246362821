import { useMemo } from 'react';
import { SelectOption } from 'types/selectOptions';
import { useLeadersQuery } from './useLeadersQuery';

export const useLeadersOptions = () => {
  const query = useLeadersQuery();

  const options: Array<SelectOption> = useMemo(() => {
    const output = [{ label: 'Brak lidera', value: '-1' }];

    if (query.data)
      return [
        ...output,
        ...query.data.data.results.map((leader) => ({
          label: `${leader.firstName || ''} ${leader.lastName || ''}`,
          value: leader.id.toString(),
        })),
      ];

    return output;
  }, [query.data]);

  return {
    options,
    isLoading: query.isLoading,
  };
};
