const styles = {
  root: {
    py: '0.4rem',
  },
  accordion: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'space-between',
  },
  accordionSection: {
    alignItems: 'strech',
  },
};

export default styles;
