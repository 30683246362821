import { PropsWithChildren } from 'react';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  Button,
  VStack,
} from '@chakra-ui/react';
import FormWrapper from 'components/Form/FormWrapper';
import { saveButtonStyling } from './styles';

interface Props<T> extends PropsWithChildren {
  header: string;
  saveText: string;
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: SubmitHandler<T extends FieldValues ? any : any>;
}

const Modal = <T,>({
  header,
  isOpen,
  onClose,
  onSubmit,
  saveText,
  children,
}: Props<T>) => (
  <ChakraModal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent padding="1rem">
      <ModalHeader>{header}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box>
          <FormWrapper onSubmit={onSubmit}>
            <VStack>
              <Box width="100%">{children}</Box>
              <Button sx={saveButtonStyling} type="submit">
                {saveText}
              </Button>
            </VStack>
          </FormWrapper>
        </Box>
      </ModalBody>
    </ModalContent>
  </ChakraModal>
);

export default Modal;
