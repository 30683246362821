// request paths
const leadersPrefix = 'leaders/';

export const loggedUserApiUrl = 'users/user/';
export const projectsApiUrl = `${leadersPrefix}project/`;
export const meanVelocitiesApiUrl = `mean-velocities/`;
export const leadersApiUrl = `${leadersPrefix}leader/`;

// page paths
export const basePath = '/';
export const logoutPath = `/logout/`;
export const unauthorizedPath = `/unauthorized`;
export const projectsPath = `/project`;
export const projectPath = `/project/:id/`;
