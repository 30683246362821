import { Nullable } from 'types/nullable';

export interface Leader {
  id: number;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
}

export enum ProjectStatus {
  productDesign = 'product_design',
  development = 'development',
  maintenance = 'maintenance',
  completed = 'completed',
}

interface BaseProject {
  name: string;
  boardId: number;
  apiName: string;
  apiUsername: string;
  apiToken: string;
  status: ProjectStatus;
  leader?: Nullable<Leader>;
}

export interface Project extends BaseProject {
  supportsSprints: boolean;
}

export interface ProjectWithId extends Project {
  id: number;
}

export interface ProjectForCreate extends Omit<BaseProject, 'leader'> {
  leader: Nullable<number>;
}
