import {
  Icon,
  Text,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  HStack,
} from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GrouppedRedirectList, RedirectList } from 'components/Layout/types';
import { SideBarIconProps } from './SidebarButton.types';
import { IconColumnType } from '../IconColumn';
import styles from './SidebarButton.styles';

const SidebarButton: FC<SideBarIconProps> = ({
  action,
  toggleDrawer,
  type,
  toggleAccordion,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { name, text, activeIcon, icon, path } = action as RedirectList;

  const { items } = action as GrouppedRedirectList;

  const isFullType = type === IconColumnType.full;
  const isExpandable = items?.length && isFullType;
  const isActive = useMemo(
    () => pathname === path || items?.some((item) => item.path === pathname),
    [items, path, pathname]
  );

  const onClick = useCallback(() => {
    toggleAccordion();

    if (!isExpandable) {
      if (path) {
        return navigate(path);
      }

      return toggleDrawer();
    }
  }, [isExpandable, navigate, path, toggleAccordion, toggleDrawer]);

  return (
    <AccordionItem border="none" onClick={onClick}>
      <AccordionButton sx={{ ...styles.button, ...styles.active(isActive) }}>
        <HStack sx={styles.button}>
          <Icon
            sx={styles.icon}
            as={isActive ? activeIcon : icon}
            aria-label={name}
          />
          {isFullType ? <Text>{text}</Text> : null}
        </HStack>
        {isExpandable ? <AccordionIcon /> : null}
      </AccordionButton>
      {isExpandable ? (
        <AccordionPanel sx={styles.nestedItems}>
          {items.map(({ path, text }, i) => (
            <HStack
              key={`subsection-${i}`}
              sx={styles.active(pathname === path)}
              onClick={() => navigate(path)}
            >
              <Text>{text}</Text>
            </HStack>
          ))}
        </AccordionPanel>
      ) : null}
    </AccordionItem>
  );
};

export default SidebarButton;
