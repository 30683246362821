import { FC } from 'react';
import { useMutation } from 'react-query';
import client from '../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { FormSelect } from 'components/Form/Inputs/select';
import { TextInput } from 'components/Form/Inputs/textInput';
import { requiredValidation } from '../../../components/Form/validation';
import { ApiError } from '../../../types/apiError';
import { handleError } from '../../../utils/handleError';
import { projectsApiUrl } from '../../../utils/paths';
import { AddProjectFormProps, DataFromForm } from './types';
import { ProjectForCreate, ProjectWithId } from '../types';
import CenteredSpinner from 'components/CenteredSpinner';
import { useLeadersOptions } from '../hooks/useLeadersOptions';
import Modal from 'components/Modal';
import { projectStatusOptions, PROJECTS_QUERY_KEY } from '../consts';

export const AddProject: FC<AddProjectFormProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const addProject = useMutation(
    (dataForCreate: ProjectForCreate) =>
      client.post<{ results: ProjectWithId }>(projectsApiUrl, dataForCreate),
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: Array<ProjectWithId> }>
          | undefined = queryClient.getQueryData(PROJECTS_QUERY_KEY);

        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];

        queryClient.setQueryData(PROJECTS_QUERY_KEY, oldData);

        closeDrawer();
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const methods = useForm<ProjectForCreate>({});

  const handleSubmit = async (data: DataFromForm) => {
    const { name, jiraLink, apiUsername, apiToken, status, leader } = data;

    const temURL = new URL(jiraLink);

    const apiName = temURL.hostname.split('.', 3)[0];
    const boardId = Number(temURL.pathname.split('/', 8).at(-1));

    const dataForMutate = {
      name,
      apiName,
      boardId,
      apiUsername,
      apiToken,
      status,
      leader: leader === '-1' ? null : Number(leader),
    };

    await addProject.mutateAsync(dataForMutate);
  };

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  const { options, isLoading } = useLeadersOptions();

  if (isLoading) return <CenteredSpinner />;

  return (
    <FormProvider {...methods}>
      <Modal
        header="Dodaj nowy projekt"
        saveText="Dodaj projekt"
        isOpen={isOpen}
        onClose={closeDrawer}
        onSubmit={handleSubmit}
      >
        <>
          <TextInput
            name={'jiraLink'}
            label="Link do Jira"
            autoFocus
            registerProps={requiredValidation}
          />
          <TextInput
            name={'apiUsername'}
            label="Email"
            registerProps={requiredValidation}
          />
          <TextInput
            name={'apiToken'}
            label="Token"
            registerProps={requiredValidation}
          />
          <TextInput
            name={'name'}
            label="Nazwa projektu"
            registerProps={requiredValidation}
          />
          <FormSelect
            options={options}
            name={'leader'}
            label="Lider"
            registerProps={requiredValidation}
          />
          <FormSelect
            options={projectStatusOptions}
            name={'status'}
            label="Status"
            registerProps={requiredValidation}
          />
        </>
      </Modal>
    </FormProvider>
  );
};
