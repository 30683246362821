export const detailDisplayStyling = {
  mainContainer: (showBorder?: boolean) => ({
    alignItems: 'flex-start',
    borderRightColor: 'borders.container',
    borderRightWidth: showBorder ? '1px' : '0',
  }),
  innerContainer: { paddingRight: '0.5rem' },
  value: (bold?: boolean) => ({
    fontSize: '1.25rem',
    fontWeight: bold ? 600 : 400,
    whiteSpace: 'nowrap',
  }),
  label: { fontSize: '0.75rem', fontWeight: 400 },
};
