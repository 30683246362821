export const blue = {
  900: '#04153A',
  800: '#062460',
  700: '#083286',
  600: '#083286',
  500: '#0C47C0',
  400: '#195FF1',
  300: '#5287F4',
  200: '#A9C3FA',
  100: '#E2EBFD',
  50: '#F3F7FE',
};

export const gray = {
  300: '#676767',
  200: '#BFBFBF',
  100: '#EEEEEE',
};

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  active: '#0A399A',
  unActive: gray[300],
  text: {
    active: '#2B6CB0',
    unactive: '#4A5568',
  },
  borders: {
    tableContainer: blue[100],
    container: blue[100],
    rowBottom: '#D9D9D9',
  },
  background: '#F7F9FB',
  button: { active: blue[600], focus: blue[600] },
  hover: { button: blue[600], table: blue[100] },
  blue,
  gray,
  green: '#5D5D5D',
  mainBackground: '#F7F9FB',
};

export const chartColors = {
  chartColor1: '#F58B72',
  chartColor2: '#FBD1C7',
  chartColor3: '#8751DA',
  chartColor4: '#CFB9F0',
  chartColor5: '#79B84F',
  chartColor6: '#C9E3B9',
  chartColor7: '#0C47C0',
  chartColor8: '#A9CC3FA',
  chartColor9: '#F1A245',
  chartColor10: '#F9DAB5',
};
