import { LineChartData } from 'components/Charts/LineChart/types';
import { useMemo } from 'react';
import { EmployeeSummary } from '../types';

const useDataForLineChart = (data?: Array<EmployeeSummary>) =>
  useMemo(() => {
    if (!data?.length) return;

    const tempData = data.map((employeeSum) => ({
      employeeName: employeeSum.employee.displayName,
      sprintVelocities: employeeSum.meanVelocities.map((meanVelocity) => ({
        sprintName: meanVelocity.sprints[0].name,
        sprintDateStarted: meanVelocity.sprints[0].startDate,
        meanVelocityHour: meanVelocity.meanVelocityHour,
        meanVelocityMd: meanVelocity.meanVelocityMd,
      })),
    }));

    const sortedTempData = tempData.map((value) => ({
      ...value,
      sprintVelocities: value.sprintVelocities.sort((a, b) =>
        a.sprintDateStarted.localeCompare(b.sprintDateStarted)
      ),
    }));

    const horizontalAxislabels = sortedTempData[0].sprintVelocities.map(
      (value) => value.sprintName
    );

    const meanVelocitiesHour: LineChartData = {
      horizontalAxislabels,
      datasets: sortedTempData.map((value) => ({
        label: value.employeeName,
        data: value.sprintVelocities.map((value) =>
          Number(value.meanVelocityHour)
        ),
      })),
    };

    const meanVelocitiesMd: LineChartData = {
      horizontalAxislabels,
      datasets: sortedTempData.map((value) => ({
        label: value.employeeName,
        data: value.sprintVelocities.map((value) =>
          Number(value.meanVelocityMd)
        ),
      })),
    };

    return { meanVelocitiesHour, meanVelocitiesMd };
  }, [data]);

export const useProject = (data?: Array<EmployeeSummary>) =>
  useDataForLineChart(data);
